import * as React from 'react'
// static
import Avatar from '../../../assets/imgs/avatar.png'
// styles
import './styles.css'

const HeroComponent = ({ scrollRef }) => (
  <section ref={scrollRef} className='hero-section'>
    <div className='box'>
      <div className='hero-grid'>
        <div className='hero-content'>
          <h2 className='main-title'>Workforce Intelligence</h2>
          <div>
            <p className='hero-user-quote'>
              “We have worked closely with the Pod team and other corporate
              partners to make this project come to life.”
            </p>
          </div>
          <div className='hero-feedback'>
            <img src={Avatar} alt='avatar' />
            <div>
              <p className='hero-feedback-name'>Olivia Holt-Ivry</p>
              <p className='hero-feedback-position'>U.S. Department of State</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default HeroComponent
