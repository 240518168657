import * as React from 'react'
// components
import Header from '../components/layouts/Header'
import HeroComponent from '../components/sections/HeroComponent'
import Footer from '../components/layouts/Footer'
import Overview from '../components/sections/Overview'
import News from '../components/sections/News'
import Company from '../components/sections/Company'
// hooks
import { useFadeAnimation } from '../hooks/animationHomePresset'

const Home = () => {
  const heroRef = React.useRef(null)
  const overviewRef = React.useRef(null)
  const newsRef = React.useRef(null)
  // const company = React.useRef(null)

  const scrollAction =
    (ref, offset = 0) =>
    () => {
      let position = ref.current.getBoundingClientRect()

      window.scrollTo(position.left, position.top + window.scrollY + offset)
    }

  useFadeAnimation()

  return (
    <>
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <div className='podio'>
            <HeroComponent scrollRef={heroRef} />
            <Overview scrollRef={overviewRef} />
            <News scrollRef={newsRef} />
            {/*<Company scrollRef={company} />*/}
            <Footer />
          </div>
        </div>
      </div>
      <Header
        scrollToHero={scrollAction(heroRef)}
        scrollToOverview={scrollAction(overviewRef, -70)}
        scrollToNews={scrollAction(newsRef, -30)}
        // scrollToCompany={scrollAction(company, -90)}
      />
    </>
  )
}

export default Home
