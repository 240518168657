import { BrowserRouter, Routes, Route } from 'react-router-dom'
// components
import Home from './views/Home'
// styles
import './styles/global.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
