import * as React from 'react'
// static
import { ReactComponent as StartCards } from '../../../assets/imgs/overview/start.svg'
import { ReactComponent as TalentPoolAndDecisionCards } from '../../../assets/imgs/overview/talentPool.svg'
import { ReactComponent as EmployeeExperience } from '../../../assets/imgs/overview/employeeExperience.svg'
import { ReactComponent as Support } from '../../../assets/imgs/overview/support.svg'
import { ReactComponent as Chart } from '../../../assets/imgs/overview/chart.svg'
// styles
import './styles.css'
// constants
import { overviewSectionItems } from './constants'
// components
import DropList from './components/DropList'
import Carousel from './components/Carousel'

const Overview = ({ scrollRef }) => {
  const {
    start,
    talentPool,
    decision,
    workforceAnalysis,
    futureWorkForce,
    employeeExperience,
    support,
    chart,
    security,
  } = overviewSectionItems

  return (
    <section ref={scrollRef} className='overview-section'>
      <div className='box'>
        <div className='overview-grid'>
          <div id='start' className='grid-10 padding-top-10'>
            <StartCards className='elements span-6 padding-r-5' />
            <div className='applications-content overview span-4 padding-l-5 primary-row'>
              <h2 className='main-title'>{start.title}</h2>
              <p className='description'>{start.description}</p>
            </div>
          </div>

          <div id='talent' className='grid-10 padding-top-10'>
            <div className='applications-content span-4 flex-column'>
              <span className='overview-title talent'>{talentPool.title}</span>
              <p className='description margin-b-5 talent-text'>
                {talentPool.description}
              </p>
              <span className='overview-title decision'>{decision.title}</span>
              <p className='description decision-text'>
                {decision.description}
              </p>
            </div>
            <TalentPoolAndDecisionCards className='elements span-6' />
          </div>

          <div id='heat-map' className='grid-10 padding-top-20 '>
            <Carousel />
            <div className='applications-content span-5 padding-l-5 primary-row'>
              <span className='overview-title heat-mapping'>
                {workforceAnalysis.title}
              </span>
              <p className='description'>{workforceAnalysis.description}</p>
              <DropList />
            </div>
          </div>

          <div id='future-force' className='span-12 padding-top-10'>
            <span className='overview-title heat-mapping'>
              {futureWorkForce.title}
            </span>
            <p className='description'>{futureWorkForce.description}</p>
          </div>

          <div id='employee-experience' className='grid-10'>
            <div className='employee-experience span-5 padding-r-5'>
              <span className='overview-title heat-mapping'>
                {employeeExperience.title}
              </span>
              <p className='description'>{employeeExperience.description}</p>
            </div>
            <div className='span-5'>
              <EmployeeExperience className='elements' />
            </div>
          </div>

          <div id='support' className='grid-10 padding-top-4'>
            <div className='span-5 padding-r-5'>
              <Support className='elements' />
            </div>
            <div className='span-5 padding-l-5 primary-row'>
              <span className='overview-title heat-mapping'>
                {support.title}
              </span>
              <p className='description'>{support.description}</p>
            </div>
          </div>

          <div id='chart' className='grid-10'>
            <div className='span-4 '>
              <span className='overview-title heat-mapping'>{chart.title}</span>
              <p className='description'>{chart.description}</p>
              <span className='overview-title heat-mapping'>
                {security.title}
              </span>
              <p className='description'>{security.description}</p>
            </div>
            <div className='span-6 padding-l-5'>
              <Chart className='elements' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Overview
