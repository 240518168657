export const overviewSectionItems = {
  start: {
    title: 'Overview',
    description:
      'The Pod Workforce Intelligence Platform is designed to empower organizations to transform their approach to talent management and workforce development. By leveraging zero-bias AI, geographical heat mapping, and a scalable prospect database, Pod enables businesses and government bodies to streamline their talent acquisition processes, make data-driven decisions, and build a future-ready workforce. Pod’s platform seamlessly integrates into your existing systems, providing immediate value and long-term strategic advantage.',
  },
  talentPool: {
    title: 'Centralized Talent Pool Management',
    description:
      'Pod unifies various talent sources into a dynamic and comprehensive platform. Whether drawing from internal employees, historical candidates, colleges, or external partners, Pod consolidates talent data to optimize recruitment efforts. By tapping into both internal and external pipelines, your organization gains access to a diverse and highly qualified talent pool for current and future needs. Pod helps re-engage past candidates, reducing the need to constantly source new prospects and saving both time and resources in the hiring process.',
  },
  decision: {
    title: 'Ethical AI. Transparent and Fair Decision-Making',
    description:
      'At Pod, we prioritize ethical and transparent use of AI to support decision-making while maintaining human oversight. Our internal algorithms are designed with zero-bias.',
  },
  workforceAnalysis: {
    title: 'Heat Mapping for Strategic Workforce Analysis',
    description:
      'Pod’s powerful heat mapping functionality enables organizations to visualize talent distribution across regions, providing insights into skill concentrations and gaps. This tool equips organizations with the information needed to strategically manage and optimize their workforce.',
  },
  futureWorkForce: {
    title: 'Future-Proof Your Workforce with a Skills-Based Approach',
    description:
      'Partnering with Pod enables your organization to adopt a skills-based taxonomy, providing a strategic advantage. Our AI-driven platform categorizes and tracks skills at scale, ensuring the right talent is always matched to the right roles. This skills-based framework allows for internal mobility, identifying skill gaps and upskilling opportunities that enhance collaboration across teams and regions. With Pod, your organization gains the agility needed to respond to shifting skill demands while fostering a culture of continuous development, ensuring your workforce remains competitive and future-ready.',
  },
  employeeExperience: {
    title:
      'Enhanced Employee Experience. Empowering Employees to Drive Their Own Career Growth',
    description:
      'Pod isn’t just a tool for Senior management—it empowers employees to take control of their own career development. Through personalized career profiles, employees can map their career trajectory, explore new opportunities within the organization, and receive AI-driven recommendations for upskilling and lateral moves. With clear career pathways aligned with organizational goals, Pod fosters a culture of continuous growth, where employees can actively plan for their future, ensuring long-term engagement and retention.',
  },
  support: {
    title: 'Seamless Integration with Tailored Support',
    description:
      'Pod is designed to integrate seamlessly with your existing ATS, CRM, and other HR systems, whether you use off-the-shelf solutions or customized, legacy software. We understand that integration can present challenges, especially for organizations with unique configurations. To ensure a smooth process, our team works closely with your IT department to tailor the integration to your specific needs, providing detailed guidance and support every step of the way. We offer pre-built connectors and customizable APIs to handle complex data flows, ensuring that Pod complements your existing systems without disruption. Additionally, our dedicated implementation team is available to troubleshoot and address any integration challenges, minimizing downtime and ensuring you can start seeing value from day one.',
  },
  chart: {
    title: 'Real-Time Dashboards and Key Metrics',
    description:
      'With Pod, you have access to customizable, real-time dashboards that provide critical workforce insights. Metrics such as Time to Hire, Time to Referral, and pipeline health are available at your fingertips, enabling continuous monitoring and optimization of recruitment efforts. This data-driven approach ensures that talent strategies are always aligned with your broader organizational goals.',
  },
  security: {
    title: 'Security and Compliance',
    description:
      'Pod is designed with robust security and compliance measures, ensuring that all candidate and employee data is managed securely. Built to meet stringent regulatory standards, including GDPR, HIPAA, and FedRAMP, Pod offers peace of mind that sensitive data is protected at all times, in accordance with the highest industry standards.',
  },
}

export const dropdownItems = [
  {
    label: 'Accelerated Workforce Development',
    description: `Pod allows organizations to visualize existing workforce capabilities
          in key sectors such as technology, healthcare, and manufacturing. This
          enables faster upskilling and readiness-building, ensuring critical
          industries thrive and grow.`,
  },
  {
    label: 'Talent Sharing and Resource Allocation',
    description: `Pod facilitates collaboration across borders or regions by identifying
          strengths and enabling efficient talent sharing and resource
          allocation across an organization’s network.`,
  },
  {
    label: 'Strategic Investment',
    description: `Pod helps organizations pinpoint areas for workforce development,
          allowing leadership to prioritize investments in regions with high
          demand for specific skills, supporting growth and operational
          efficiency in key sectors.`,
  },
  {
    label: 'Market Comparison',
    description: `Pod enables organizations to measure their workforce strategies
          against trending public sector job data to understand how the larger
          talent marketplace maps against their own. This comparison ensures
          workforce strategies are competitive and aligned with broader industry
          trends.`,
  },
  {
    label: 'Emergency Preparedness',
    description: `In critical scenarios, such as threats or supply chain disruptions,
          Pod provides the ability to locate and mobilize talent rapidly. By
          identifying where essential skills are concentrated, Pod helps
          organizations respond quickly and efficiently.`,
  },
]
