import React from 'react'
// libs
import { gsap } from 'gsap'
import classNames from 'classnames'
// constants
import { hiddenStyles } from './constants'

const DropdownItem = ({ item }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const contentRef = React.useRef(null)

  const arrowClass = classNames('down', { 'open-list': isOpen })

  const toggleDropdown = () => {
    setIsOpen(!isOpen)

    gsap.to(
      contentRef.current,
      isOpen
        ? {
            ...hiddenStyles,
            duration: 1.2,
          }
        : {
            opacity: 1,
            height: '100%',
            overflow: 'unset',
            duration: 1.2,
            margin: '2.5rem 0',
          }
    )
  }

  return (
    <div className='dropdown-item width-100'>
      <div className='dropdown-title width-100' onClick={toggleDropdown}>
        {item.label}
        <span className={arrowClass}></span>
      </div>
      <div
        className='description dropdown-content'
        ref={contentRef}
        style={hiddenStyles}
      >
        {isOpen && item.description}
      </div>
    </div>
  )
}

export default DropdownItem
