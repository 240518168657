import * as React from 'react'
// libs
import { gsap } from 'gsap'
// static
import Map1 from '../../../../../assets/imgs/map/map1-min.png'
import Map2 from '../../../../../assets/imgs/map/map2-min.png'
import Map3 from '../../../../../assets/imgs/map/map3-min.png'
import Map4 from '../../../../../assets/imgs/map/map4-min.png'
import Map5 from '../../../../../assets/imgs/map/map5-min.png'
import Map6 from '../../../../..//assets/imgs/map/map6-min.png'
// styles
import './styles.css'

const slides = [Map1, Map2, Map3, Map4, Map5, Map6]

const Carousel = () => {
  const trackRef = React.useRef([])

  React.useEffect(() => {
    gsap.set(trackRef.current[0], { autoAlpha: 1 })

    let currentIndex = 0

    const fadeAnim = () => {
      const currentImg = trackRef.current[currentIndex]
      const nextIndex = (currentIndex + 1) % slides.length
      const nextImg = trackRef.current[nextIndex]

      gsap
        .timeline()
        .to(currentImg, { autoAlpha: 0, duration: 2 }, 0)
        .to(nextImg, { autoAlpha: 1, duration: 2 }, 0)

      currentIndex = nextIndex

      gsap.delayedCall(3, fadeAnim)
    }

    gsap.delayedCall(3, fadeAnim)
  }, [])

  return (
    <div className='span-5 padding-r-5 carousel carousel-elements'>
      <img
        src={Map1}
        className='elements hid-img'
        alt='Default map background'
        style={{
          opacity: 0,
        }}
      />
      {slides.map((slide, index) => (
        <img
          key={index}
          ref={(el) => (trackRef.current[index] = el)}
          className='carousel-slide elements'
          src={slide}
          alt={`Map ${index + 1}`}
        />
      ))}
    </div>
  )
}

export default Carousel
