import * as React from 'react'
// styles
import './styles.css'
// constants
import { dropdownItems } from '../../constants'
// components
import Item from './Item'

const DropList = () => (
  <div className='dropdown-wrapper width-100'>
    <span className='description font-bold'>Key benefits include:</span>
    <div className='margin-t-2'>
      {dropdownItems.map((item) => (
        <Item key={item.label} item={item} />
      ))}
    </div>
  </div>
)

export default DropList
