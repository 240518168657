import * as React from 'react'
// libs
import classNames from 'classnames'
// static
import Logo from '../../../assets/imgs/logo.svg'
// styles
import './styles.css'

const Header = ({
  scrollToOverview,
  scrollToHero,
  // scrollToCompany,
  scrollToNews,
}) => {
  const [open, setOpen] = React.useState(false)

  React.useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth > 750 && open) {
        setOpen(false)
      }
    }

    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [open])

  const burgerClassName = classNames('burger', { open: open })
  const navClassName = classNames('menu', { open: open })

  const onItemClick = (fn) => () => {
    setOpen(false)
    if (fn) {
      fn()
    }
  }
  return (
    <header>
      <div className='box'>
        <div className='header-content'>
          <img onClick={scrollToHero} src={Logo} alt='Logo' />
          <div className='nav-menu'>
            <button className={burgerClassName} onClick={() => setOpen(!open)}>
              <div className='line' />
              <div className='line' />
              <div className='line' />
            </button>
            <nav className={navClassName}>
              <img onClick={onItemClick(scrollToHero)} src={Logo} alt='Logo' />
              <p onClick={onItemClick(scrollToOverview)}>Overview</p>
              <p onClick={onItemClick(scrollToNews)}>News</p>
              {/*<p onClick={onItemClick(scrollToCompany)}>Company</p>*/}
              <a href='mailto:chris@pod.io?subject=' onClick={onItemClick()}>
                Contact Us
              </a>
            </nav>
            <div className='menu-desktop'>
              <p onClick={scrollToOverview}>Overview</p>
              <p onClick={scrollToNews}>News</p>
              {/*<p onClick={scrollToCompany}>Company</p>*/}
              <a href='mailto:chris@pod.io?subject='>Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
