import * as React from 'react'
// libs
import { gsap } from 'gsap'
import {
  DrawSVGPlugin,
  EaselPlugin,
  MotionPathHelper,
  ScrollSmoother,
  ScrollToPlugin,
  ScrollTrigger,
  Draggable,
  InertiaPlugin,
  SplitText,
  TextPlugin,
} from 'gsap/all'

export const useFadeAnimation = () => {
  gsap.registerPlugin(
    ScrollTrigger,
    ScrollToPlugin,
    EaselPlugin,
    TextPlugin,
    DrawSVGPlugin,
    Draggable,
    InertiaPlugin,
    ScrollSmoother,
    MotionPathHelper,
    SplitText
  )

  const ref = React.useRef(null)

  React.useEffect(() => {
    const title = document.querySelector('.hero-section h2')
    const feedback = document.querySelector('.hero-feedback')
    const userName = document.querySelector('.hero-user-quote')

    if (!title || !feedback || !userName) {
      console.error('Elements for animation not found!')
      return
    }
    ref.current = gsap.timeline({})

    ref.current
      .fromTo(
        title,
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.in',
        },
        '<0.25'
      )
      .fromTo(
        feedback,
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.in',
        },
        '<0.25'
      )
      .fromTo(
        userName,
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.in',
        },
        '<0.25'
      )

    const Overview_H2 = new SplitText('#start h2', {
      type: 'lines',
    })

    const startOverview = gsap.timeline({
      scrollTrigger: {
        trigger: '#start',
        start: 'top 190px',
        end: '+=500',
      },
    })

    startOverview
      .fromTo(
        Overview_H2.lines,
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.in',
        }
      )
      .fromTo(
        '#start p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.in',
        },
        '<0.25'
      )
      .fromTo(
        '#Path_45989',
        {
          drawSVG: 0,
        },
        {
          drawSVG: '100%',
          duration: 3,
          repeat: -1,
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#Group_46517',
        {
          x: -700,
          y: 0,
        },
        {
          opacity: 1,
          x: '+=25',
          y: '+=25',
          duration: 3,
          ease: 'power2.inOut',
          repeat: -1,
          yoyo: true,
        },
        0.65
      )
      .fromTo(
        '#Group_46513',
        {
          x: -800,
        },
        {
          opacity: 1,
          x: '+=25',
          duration: 2,
          ease: 'power2.inOut',
          repeat: -1,
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#Group_46515',
        {
          y: -350,
        },
        {
          opacity: 1,
          y: '+=25',
          duration: 3,
          ease: 'power2.inOut',
          repeat: -1,
          yoyo: true,
        },
        0
      )

    const talent = gsap.timeline({
      scrollTrigger: {
        trigger: '#talent',
        start: 'top 300px',
        end: '+=500',
      },
    })

    let cardsAnim = gsap.timeline({})

    cardsAnim
      .fromTo(
        '#Group_46530',
        {
          x: '20px',
        },
        {
          x: '40px',
          yoyo: true,
          repeat: -1,
          duration: 2.5,
          ease: 'power1.inOut',
        }
      )
      .fromTo(
        '#Group_46527',
        {
          y: '640px',
        },
        {
          y: '660px',
          yoyo: true,
          repeat: -1,
          duration: 2.5,
          ease: 'power1.inOut',
        },
        0
      )
      .fromTo(
        '#Group_46516',
        {
          x: '0px',
        },
        {
          x: '25px',
          yoyo: true,
          repeat: -1,
          duration: 2,
          ease: 'power1.inOut',
        },
        0
      )
      .fromTo(
        '#Group_46529',
        {
          x: '-10px',
        },
        {
          x: '15px',
          yoyo: true,
          repeat: -1,
          duration: 3,
          ease: 'power1.inOut',
        },
        0
      )

    ref.current
      .to(
        '.gear',
        {
          keyframes: [{ rotation: 360, duration: 2 }],
          transformOrigin: '50% 50%',
          repeat: -1,
          paused: false,
        },
        0
      )
      .to(
        '.gear-reverse',
        {
          keyframes: [{ rotation: -360, duration: 2 }],
          transformOrigin: '50% 50%',
          repeat: -1,
          paused: false,
        },
        0
      )
    talent
      .fromTo(
        '.talent',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        }
      )
      .fromTo(
        '.talent-text',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<'
      )
      .fromTo(
        '.decision',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        },
        '<'
      )
      .fromTo(
        '.decision-text',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<'
      )
      .fromTo(
        '#Group_44299 rect',
        {
          width: '1',
        },
        {
          onUpdate: function () {
            const res = Math.round(
              (parseInt(this.targets()[0].style.width) * 100) / 164
            )
            document.querySelector('#_49_ tspan').textContent = res + '%'
          },
          width: '80',
          yoyo: true,
          duration: 3.5,
          ease: 'power1.in',
        },
        0
      )
      .fromTo(
        '#Group_44299-2 rect',
        {
          width: '14',
        },
        {
          onUpdate: function () {
            const res = Math.round(
              (parseInt(this.targets()[0].style.width) * 100) / 171
            )
            document.querySelector('#_61_ tspan').textContent = res + '%'
          },
          width: '104',
          duration: 4.5,
          ease: 'power1.in',
        },
        0
      )
      .fromTo(
        '#Group_44297 rect',
        {
          width: '19',
        },
        {
          onUpdate: function () {
            const res = Math.round(
              (parseInt(this.targets()[0].style.width) * 100) / 175
            )
            document.querySelector('#_62_ tspan').textContent = res + '%'
          },
          width: '109',
          duration: 4.5,
          ease: 'power1.in',
        },
        0
      )
      .fromTo(
        '#Line_1264',
        {
          attr: {
            x2: 30,
          },
        },
        {
          attr: {
            x2: 140,
          },
          duration: 5,
          ease: 'power1.on',
        },
        0
      )
      .fromTo(
        '#Line_1284',
        {
          attr: {
            x2: 25,
          },
        },
        {
          attr: {
            x2: 133,
          },
          duration: 5,
          ease: 'power1.in',
        },
        0
      )
      .fromTo(
        '#Line_1284-2',
        {
          attr: {
            x2: 22,
          },
        },
        {
          attr: {
            x2: 122,
          },
          duration: 5,
          ease: 'power1.inOut',
        },
        0
      )
      .fromTo(
        '#Line_1291-2',
        {
          attr: {
            x2: 21,
          },
        },
        {
          attr: {
            x2: 118,
          },
          duration: 5,
          ease: 'power1.inOut',
        },
        0
      )
      .fromTo(
        '#Line_1291-3',
        {
          attr: {
            x2: 20,
          },
        },
        {
          attr: {
            x2: 109,
          },
          duration: 5,
          ease: 'power1.inOut',
        },
        0
      )

    const heatMap = gsap.timeline({
      scrollTrigger: {
        trigger: '#heat-map',
        start: 'start 300px',
        end: '+=500',
      },
    })

    heatMap
      .fromTo(
        '#heat-map span',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        }
      )
      .fromTo(
        '#heat-map p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<0.25'
      )

    let employee = gsap.timeline({})

    employee
      .fromTo(
        '#Group_46785',
        {
          x: '-715',
        },
        {
          x: '-740',
          duration: 3,
          repeat: -1,
          yoyo: true,
        }
      )
      .fromTo(
        '#Group_46784',
        {
          x: '-5',
          y: '-235',
        },
        {
          x: '25',
          y: '-220',
          duration: 2,
          repeat: -1,
          yoyo: true,
        },
        0
      )

    const futureForceAndExp = gsap.timeline({
      scrollTrigger: {
        trigger: '#future-force',
        start: 'top 300px',
        end: '+=500',
      },
    })

    futureForceAndExp
      .fromTo(
        '#future-force span',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        }
      )
      .fromTo(
        '#future-force p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<0.25'
      )
      .fromTo(
        '#employee-experience p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<0.25'
      )
      .fromTo(
        '.path-web-dev',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power4.out',
        },
        0
      )
      .fromTo(
        '.web-dev-arrow',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.1'
      )
      .fromTo(
        '.path-senior-dev',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.5'
      )
      .fromTo(
        '.web-senior-arrow',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.1'
      )
      .fromTo(
        '.path-full-dev',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.5'
      )

    const support = gsap.timeline({
      scrollTrigger: {
        trigger: '#support',
        start: 'top 400px',
        end: '+=500',
      },
    })

    support
      .fromTo(
        '#support span',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        },
        '<0.25'
      )
      .fromTo(
        '#support p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<0.25'
      )

    const chart = gsap.timeline({
      scrollTrigger: {
        trigger: '#chart',
        start: 'top 400px',
        end: '+=500',
      },
    })

    chart
      .fromTo(
        '#chart span',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        },
        '<0.25'
      )
      .fromTo(
        '#chart p',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
        '<0.25'
      )
      .fromTo(
        '#chart-data',
        {
          x: '-10',
        },
        {
          x: '20',
          duration: 2,
          repeat: -1,
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#chart-spider',
        {
          y: '0',
        },
        {
          y: '30',
          duration: 2,
          repeat: -1,
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#chart-icon',
        {
          y: '0',
          x: '-5',
        },
        {
          y: '10',
          x: '20',
          duration: 2,
          repeat: -1,
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#chart-map',
        {
          x: '-10',
        },
        {
          x: '30',
          duration: 3,
          repeat: -1,
          yoyo: true,
        },
        0
      )

    const maps = gsap.timeline({
      scrollTrigger: {
        trigger: '.news-grid',
        start: 'top 400px',
        end: '+=500',
      },
    })

    maps
      .fromTo(
        '.news-grid h2',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
      .fromTo(
        '#map-1',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
      .fromTo(
        '#map-2',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
      .fromTo(
        '#map-3',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
      .fromTo(
        '#map-4',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
      .fromTo(
        '#map-5',
        {
          opacity: 0,
          y: '15px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power4.out',
        },
        '<0.25'
      )
  }, [])
}
