import * as React from 'react'
// static
import LogoNSF from '../../../assets/imgs/overview/logoNSF.png'
import Map1 from '../../../assets/imgs/news/news-map1.png'
import Map2 from '../../../assets/imgs/news/news-map2.png'
import Map3 from '../../../assets/imgs/news/news-map3.png'
import Map4 from '../../../assets/imgs/news/news-map4.png'
import Map5 from '../../../assets/imgs/news/news-map5.png'
// styles
import './styles.css'

const News = ({ scrollRef }) => (
  <section
    ref={scrollRef}
    className='overview-section padding-bottom-10 padding-top-10'
  >
    <div className='box'>
      <div className='news-grid'>
        <div className='applications-content overview span-12'>
          <h2 className='main-title'>Latest News</h2>
          <br />
          <br />
          <span className='overview-title'>
            Building a Future-Ready Workforce: Ohio Skills Taxonomy Project
          </span>
        </div>
        <p className='description span-6'>
          In partnership with the National Science Foundation (NSF), this
          project is pioneering the development of a dynamic skills taxonomy for
          Ohio’s workforce. With industries like Artificial Intelligence (AI),
          Electric Battery Production, and Advanced Manufacturing evolving
          rapidly, the demand for new skills is reshaping the job market.
          Supported by an EAGER grant from the NSF, the initiative aims to track
          skill demand across sectors, identify gaps, and promote workforce
          development through advanced data analysis techniques.
          <br />
          <br />
          Leveraging AI-driven methods like keyword extraction and clustering,
          the project has already delivered important insights. Early results
          reveal significant skill demand in cities like Columbus and Cleveland,
          particularly in AI and clean energy sectors. Heatmaps and skills
          intersection analyses also highlight overlapping competencies between
          industries, suggesting opportunities for cross-sector mobility and
          upskilling.
          <br />
          <br />
          The taxonomy will help organizations across Ohio stay competitive by
          providing real-time data on emerging skills, guiding workforce
          strategies, and informing training programs. With this adaptable
          framework, the project sets the stage for broader national
          implementation, ensuring industries and workers remain aligned with
          future market demands.
        </p>
        <div className='img-container span-4'>
          <img className='logo-nsf' src={LogoNSF} alt='logo-NSF' />
        </div>
      </div>
      <div className='maps-wrapper padding-top-4'>
        <div id='map-1'>
          <span className='map-title'>Artificial Intelligence</span>
          <img src={Map1} alt='map-1' style={{ marginBottom: -15 }} />
        </div>
        <div id='map-2' className='maps-wrapper'>
          <span className='map-title'>Automotive Manufacturing</span>
          <img src={Map2} alt='map-2' />
        </div>
        <div id='map-3' className='maps-wrapper'>
          <span className='map-title'>Electric Battery Production</span>
          <img src={Map3} alt='map-3' />
        </div>
        <div id='map-4' className='maps-wrapper'>
          <span className='map-title'>Information Technology</span>
          <img src={Map4} alt='map-4' />
        </div>
        <div id='map-5' className='maps-wrapper'>
          <span className='map-title'>Software Development</span>
          <img src={Map5} alt='map-5' />
        </div>
      </div>
    </div>
  </section>
)

export default News
