import * as React from 'react'
import './styles.css'

const Footer = () => (
  <footer>
    <div className='box'>
      <p>© Copyright 2024, Copyright Pod Network LLC</p>
    </div>
  </footer>
)

export default Footer